<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="data"
      sort-by="id"
      class="elevation-1 p-5"
      :search="search"
      :items-per-page="$dataTableConfig.getItemsPerPage()"
      :footer-props="$dataTableConfig.getFooterProps()"
      :loading-text="$dataTableConfig.getLoadText()"
      :loading="loading"
    >
      <template v-slot:top>
        <v-row class="mt-5">
          <v-col>
            <v-text-field
              v-model="search"
              outlined
              dense
              label="Pesquisar"
              append-icon="mdi-magnify"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:no-data>
        <v-spacer />
        <div>
          <v-row class="justify-center mt-1">
            <v-col class="col-lg-6">
              <custom-alert
                :color="$label.getColor(0)"
                text="Não foi possível obter essas informações"
              />
            </v-col>
          </v-row>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<style scoped>
.word-breaker {
  word-break: break-word;
}
</style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data: () => ({
    search: '',
    loading: true,
    data: [],
  }),
  computed: {
    headers () {
      return [
        { text: '#', align: 'start', value: 'id' },
        { text: 'Empresa', value: 'nome' },
      ]
    },
  },
  watch: {},
  created () {},
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Configurações', route: 'configuracoes' },
      { title: 'Sistema', route: 'configuracoes/sistema' },
      { title: 'Empresas' },
    ])

    this.initialize()
    this.getEmpresas()
  },
  methods: {
    initialize () {},
    getEmpresas () {
      ApiService.get('/empresa').then((res) => {
        this.data = res.data.data
        this.loading = false
      }).catch((error) => {
        this.loading = false
        console.error('Erro: ', error)
      })
    },
    getColor (boolField) {
      if (boolField === 0) {
        return 'danger'
      }

      return 'success'
    },
  },
}
</script>
